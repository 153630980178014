import { init } from "@/core/init"

/**
 * @file nostojs
 *
 * In most implementations there isn’t any need to use the Nosto's Javascript API
 * and the normal tagging implementation approach will work best and it’s the
 * easiest to implement.
 * <br/><br/>
 * Typically calling the API should be approached only after the normal
 * tagging implementation has been done and now needs to be extended with
 * something that doesn’t come out of the box. The Javascript API is useful
 * mostly in cases where the store is doing something dynamic and not reloading
 * the page. For example showing recommendations in a product quick view, that
 * opens the product in a css overlay via Ajax. Another case is when the whole
 * store is implemented as a single page application.
 *
 * <br/><br/>
 * To enable Nosto’s Javascript API, you have to include the Nosto script on all pages within the
 * store where the user might navigate.
 *
 * Replace $accountID from the code below with your own account ID and place the code within
 * the <head> section of your sites HTML content. You can find your stores account IDs
 * from the account list within the Nosto admin.
 *
 * <pre><code>
 *   &lt;script type=&quot;text/javascript&quot;&gt;
 *     (function() {
 *       var name = "nostojs";
 *       window[name] = window[name] || function(cb) {
 *         (window[name].q = window[name].q || []).push(cb);
 *       };
 *     })();
 *   &lt;/script&gt;
 *   &lt;script src=&quot;//connect.nosto.com/include/$accountID&quot; async&gt;&lt;/script&gt;
 * </code></pre>
 *
 * <br/><br/>
 * We recommend reading more on implementing Nosto
 * {@link https://docs.nosto.com/techdocs|on our documentation site.}
 *
 * <br/><br/>
 * to test that you have the Nosto Javascript API enabled is to call it through your browser’s
 * Javascript console, for example pasting the following call and making sure
 * your console then logs the message:
 *
 * @example
 * nostojs(function(api) {
 *   console.log("Hello through Nosto JS API");
 * });
 */
void init(window)
